/* eslint-disable */
import { getBookingItemTags, linkTagToBooking, unlinkTagFromBooking } from '@/methods/bookingTag';
import { ElNotification } from 'element-plus';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  currentList: [],
  dataIsUpdated: false,
};

const getters = {};

const mutations = {
  UPDATE_BOOKING_ITEM_TAGS_LIST(state, data) {
    state.list = data;
  },
  UPDATE_BOOKING_ITEM_TAGS_CURRENT_LIST(state, data) {
    state.currentList = data;
  },
  BOOKING_ITEM_TAGS_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
};

const actions = {
  getBookingItemTags({ commit, state }, params) {
    getBookingItemTags(params).then((response) => {
      commit('UPDATE_BOOKING_ITEM_TAGS_LIST', response.data.data);
      commit('privilege/UPDATE_BOOKING_TAG_PRIVILEGE', response.data.privilege, { root: true });
    });
  },

  getBookingItemTagsCurrent({ commit, state }, params) {
    getBookingItemTags(params).then((response) => {
      commit('UPDATE_BOOKING_ITEM_TAGS_CURRENT_LIST', response.data.data);
    });
  },

  linkTagToBooking({ commit, dispatch, state }, { id, data }) {
    linkTagToBooking(id, data)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('BOOKING_ITEM_TAGS_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  unlinkTagFromBooking({ commit, dispatch, state }, { id, bookingId }) {
    unlinkTagFromBooking(id, bookingId)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('BOOKING_ITEM_TAGS_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
