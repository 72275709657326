/* eslint-disable */
import { createBreakTimeItem, deleteBreakTimeItem } from '@/methods/breakTime';
import { ElNotification } from 'element-plus';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  dataIsUpdated: false,
};

const getters = {};

const mutations = {
  BREAK_TIME_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
};

const actions = {
  createBreakTimeItem({ commit, dispatch, state }, params) {
    createBreakTimeItem(params)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('BREAK_TIME_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deleteBreakTimeItem({ commit, state }, params) {
    deleteBreakTimeItem(params)
      .then((response) => {
        commit('BREAK_TIME_DATA_IS_UPDATED', true);

        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
