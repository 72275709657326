import { getSchedule, updateSchedule } from '@/methods/baseCalendar';
import { ElNotification } from 'element-plus';
import { getErrorMessage, getDenyPrivilegeObject } from '@/compositions/helpers/helpers';

const state = {
  scheduleList: [],
  currentMonth: '',
  isLoading: false,
};

const getters = {
  getScheduleList(state) {
    return state.scheduleList;
  },
};

const mutations = {
  UPDATE_SCHEDULE_LIST(state, data) {
    state.scheduleList = data;
  },
  UPDATE_IS_LOADING(state, data) {
    state.isLoading = data;
  },
  UPDATE_MONTH(state, data) {
    state.currentMonth = data;
  },
};

const actions = {
  getScheduleList({ commit, state, getters, rootGetters }, params) {
    commit('UPDATE_IS_LOADING', true);
    getSchedule(params)
      .then((response) => {
        const days = Object.values(response.data.data.days);
        const events = days.map((elem, index) => {
          let statusClass;
          let statusText;
          if (elem.isDayOff === true) {
            statusText = 'Нерабочий день';
            statusClass = 'vuecal__event-title-busy';
          } else {
            statusText = 'Рабочий день';
            statusClass = 'vuecal__event-title-free';
          }
          const payload = {
            start: elem.startAt === null ? `${elem.date} 01:00:00` : `${elem.date} ${elem.startAt}:00`,
            end: elem.startAt === null ? `${elem.date} 23:00:00` : `${elem.date} ${elem.endAt}:00`,
            title: elem.startAt === null ? 'Выходной' : `${elem.startAt} - ${elem.endAt}, ${statusText}`,
            class: statusClass,
            id: `${elem.startAt}-id:${index}`,
          };
          return payload;
        });

        commit('UPDATE_SCHEDULE_LIST', events);
        commit('privilege/UPDATE_BASE_CALENDAR_PRIVILEGE', response.data.privilege, { root: true });
      })
      .then(() => {
        commit('UPDATE_IS_LOADING', false);
      })
      .catch(() => {
        commit('privilege/UPDATE_BASE_CALENDAR_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },
  updateSchedule({ commit, state, getters, rootGetters }, params) {
    commit('UPDATE_IS_LOADING', true);
    updateSchedule(params)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });
        getSchedule(state.currentMonth)
          .then((response) => {
            const days = Object.values(response.data.data.days);
            const events = days.map((elem, index) => {
              let statusClass;
              let statusText;
              if (elem.isDayOff === true) {
                statusText = 'Нерабочий день';
                statusClass = 'vuecal__event-title-busy';
              } else {
                statusText = 'Рабочий день';
                statusClass = 'vuecal__event-title-free';
              }
              const payload = {
                start: elem.startAt === null ? `${elem.date} 01:00:00` : `${elem.date} ${elem.startAt}:00`,
                end: elem.startAt === null ? `${elem.date} 23:00:00` : `${elem.date} ${elem.endAt}:00`,
                title: elem.startAt === null ? 'Выходной' : `${elem.startAt} - ${elem.endAt}, ${statusText}`,
                class: statusClass,
                id: `${elem.startAt}-id:${index}`,
              };
              return payload;
            });

            commit('UPDATE_SCHEDULE_LIST', events);
          })
          .then(() => {
            commit('UPDATE_IS_LOADING', false);
          });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
