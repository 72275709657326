/* eslint-disable */
import {
  getPartners,
  getCurrentPartner,
  updatePartner,
  getPartnerCooperationType,
  createPartnerStorageItem,
  getPartnerStorages,
  getResponsibleUsers,
  getPartnerRatingMatrix,
  getPartnerExport,
  getPartnerUserList,
  createPartnerUserItem,
  updatePartnerUserItem,
  deletePartnerUserItem,
  getPartnerStatistics,
} from '@/methods/partner';
import { ElNotification } from 'element-plus';
import store from '@/store';
import router from '@/router';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  fullData: [],
  currentPartner: {},
  ratingMatrix: {},
  partnerStorages: [],
  partnerCooperationType: [],
  isLoading: false,
  createdStorage: null,
  filters: [],
  responsibleUsers: [],
  partnerUserList: {},
  partnerStatistics: {},
  dataIsUpdated: {
    userList: false,
  },
};

const getters = {
  getPartnerList(state) {
    return state.list;
  },
  getPartnersFullData(state) {
    return state.fullData;
  },
  getCurrentPartner(state) {
    return state.currentPartner;
  },
  getPartnerCooperationType(state) {
    return state.partnerCooperationType;
  },
  getPartnerStorages(state) {
    return state.partnerStorages;
  },
  getFilters(state) {
    return state.filters;
  },
  getResponsibleUsers(state) {
    return state.responsibleUsers;
  },
  getPartnerStatistics(state) {
    return state.partnerStatistics;
  },
};

const mutations = {
  UPDATE_PARTNER_LIST(state, data) {
    state.list = data;
  },
  UPDATE_PARTNERS(state, data) {
    state.fullData = data;
  },
  UPDATE_CURRENT_PARTNER(state, data) {
    state.currentPartner = data;
  },
  UPDATE_PARTNER_COOPERATION_TYPE(state, data) {
    state.partnerCooperationType = data;
  },
  UPDATE_IS_LOADING(state, data) {
    state.isLoading = data;
  },
  UPDATE_NEW_STORAGE(state, data) {
    state.createdStorage = data;
  },
  UPDATE_PARTNER_STORAGES(state, data) {
    state.partnerStorages = data;
  },
  UPDATE_PARTNER_RATING_MATRIX(state, data) {
    state.ratingMatrix = data;
  },
  UPDATE_FILTERS(state, data) {
    state.filters = data;
  },
  UPDATE_RESPONSIBLE_USERS(state, data) {
    state.responsibleUsers = data;
  },
  UPDATE_PARTNER_USER_LIST(state, data) {
    state.partnerUserList = data;
  },
  PARTNER_USER_LIST_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.userList = data;
  },
  UPDATE_PARTNER_STATISTICS(state, data) {
    state.partnerStatistics = data;
  },
};

const actions = {
  getPartnerList({ commit, state }, params) {
    getPartners(params)
      .then((response) => {
        commit(
          'UPDATE_PARTNER_LIST',
          response.data.data.sort((a, b) => a.name - b.name)
        );
        commit('privilege/UPDATE_PARTNER_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  getPartners({ commit, state }, params) {
    getPartners(params)
      .then((response) => {
        commit('UPDATE_PARTNERS', response.data.data);
        commit('privilege/UPDATE_PARTNER_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },
  getResponsibleUsers({ commit, state }, params) {
    getResponsibleUsers(params)
      .then((response) => {
        commit('UPDATE_RESPONSIBLE_USERS', response.data.data);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  getCurrentPartner({ commit, state }, params) {
    getCurrentPartner(params).then((response) => {
      commit('UPDATE_CURRENT_PARTNER', response.data.data);
    });
  },
  getPartnerStatistics({ commit, state }, params) {
    getPartnerStatistics(params)
      .then((res) => {
        commit('UPDATE_PARTNER_STATISTICS', res.data.data);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  getPartnerStorages({ commit, state }, params) {
    getPartnerStorages(params).then((response) => {
      commit('UPDATE_PARTNER_STORAGES', response.data.data);
    });
  },
  getPartnerCooperationType({ commit, state }, params) {
    getPartnerCooperationType(params).then((response) => {
      commit('UPDATE_PARTNER_COOPERATION_TYPE', response.data.data);
    });
  },
  getPartnerRatingMatrix({ commit, state }, params) {
    getPartnerRatingMatrix(params).then((response) => {
      commit('UPDATE_PARTNER_RATING_MATRIX', response.data.data);
    });
  },
  createPartnerStorageItem({ commit, state }, params) {
    createPartnerStorageItem(params)
      .then((response) => {
        commit('UPDATE_NEW_STORAGE', response.data.data.id);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });

        commit('booking/UPDATE_BOOKING_IS_LOADING', false, { root: true });
      });
  },
  updatePartner({ commit, state }, params) {
    commit('UPDATE_IS_LOADING', true);
    updatePartner(params)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      });
  },
  getPartnerExport({ commit, state }, params) {
    getPartnerExport(params).then((response) => {
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', response.data]);
      const correctName = response.headers['Content-Disposition']?.split(' ')[1]?.split('=')[1];

      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = correctName ? `${correctName.slice(1, -1)}.csv` : 'partner.csv';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  },

  getPartnerUserList({ commit, state }, { partnerId, params }) {
    getPartnerUserList(partnerId, params)
      .then((response) => {
        commit('UPDATE_PARTNER_USER_LIST', response.data.data);
        commit('privilege/UPDATE_MANAGE_PARTNER_USER_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  createPartnerUserItem({ commit, state }, data) {
    createPartnerUserItem(data)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('PARTNER_USER_LIST_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updatePartnerUserItem({ commit, state }, data) {
    updatePartnerUserItem(data)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('PARTNER_USER_LIST_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deletePartnerUserItem({ commit, dispatch, state }, data) {
    deletePartnerUserItem(data)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('PARTNER_USER_LIST_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
