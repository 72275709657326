/* eslint-disable */
import {
  getShipmentList,
  getShipmentListForBooking,
  createShipmentSchedule,
  updateShipmentSchedule,
  setShipmentStatus,
  getShipmentExport,
} from '@/methods/shipment';
import { ElNotification } from 'element-plus';
import store from '@/store';
import router from '@/router';
import { formatDate, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  dataIsUpdated: false,
  shipmentSchedule: [],
  currentPage: 1,
  filters: {
    'Статус поставки': ['Ожидает брони', 'Ожидает прибытия'],
  },
  bookingDialogIsShown: false,
};

const getters = {
  getShipmentList(state) {
    return state.list;
  },
  getShipmentFilters(state) {
    return state.filters;
  },
};

const mutations = {
  UPDATE_SHIPMENT_LIST(state, data) {
    state.list = data;
  },
  UPDATE_SHIPMENT_LIST_FOR_BOOKING(state, data) {
    delete data['success'];
    delete data['privilege'];

    state.list = data;
  },
  SHIPMENT_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
  UPDATE_SHIPMENT_SCHEDULE(state, data) {
    state.shipmentSchedule = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
  UPDATE_BOOKING_DIALOG_IS_SHOWN_STATUS(state, data) {
    state.bookingDialogIsShown = data;
  },
  UPDATE_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
};

const actions = {
  getShipmentList({ commit, state }, params) {
    getShipmentList(params)
      .then((response) => {
        commit('UPDATE_SHIPMENT_LIST', response.data.data);
        commit('privilege/UPDATE_SHIPMENT_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  getShipmentListForBooking({ commit, state }, params) {
    getShipmentListForBooking(params)
      .then((response) => {
        commit('UPDATE_SHIPMENT_LIST_FOR_BOOKING', response.data);
      })
      .catch((error) => {});
  },

  createShipmentSchedule({ commit, state }, params) {
    createShipmentSchedule(params)
      .then((response) => {
        commit('UPDATE_SHIPMENT_SCHEDULE', response.data.data);
        commit('UPDATE_BOOKING_DIALOG_IS_SHOWN_STATUS', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateShipmentSchedule({ commit, state }, params) {
    updateShipmentSchedule(params)
      .then((response) => {
        let failedMessage = '',
          failedItems = response.data.data.filter((item) => item.error);

        if (failedItems.length) {
          failedMessage = '<br />Не удалось создать:<br />';
          failedItems.forEach((item) => {
            failedMessage += `${new Date(item.startTime).toLocaleString('ru', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })} ${item.error}<br />`;
          });
        }

        ElNotification.success({
          message: failedMessage ? `${response.data.message} ${failedMessage}` : response.data.message,
          dangerouslyUseHTMLString: true,
          duration: 2000,
        });
        commit('UPDATE_SHIPMENT_SCHEDULE', response.data.data);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  setShipmentStatus({ commit, state }, { id, data }) {
    setShipmentStatus(id, data)
      .then((response) => {
        commit('SHIPMENT_DATA_IS_UPDATED', true);

        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  getShipmentExport({ commit, state }, params) {
    getShipmentExport(params).then((response) => {
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', response.data]);

      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `supply-data-${formatDate(new Date())}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
