/* eslint-disable */
import {
  getDirectionDocumentList,
  createDirectionDocument,
  updateDirectionDocument,
  deleteDirectionDocument,
} from '@/methods/directionDocuments';
import { ElNotification } from 'element-plus';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  dataIsUpdated: false,
};

const getters = {
  getDirectionDocumentList(state) {
    return state.list;
  },
};

const mutations = {
  UPDATE_DIRECTION_DOCUMENT_LIST(state, data) {
    state.list = data;
  },
  DIRECTION_DOCUMENTS_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
};

const actions = {
  getDirectionDocumentList({ commit, state }, params) {
    getDirectionDocumentList(params)
      .then((response) => {
        commit('UPDATE_DIRECTION_DOCUMENT_LIST', response.data.data);
        commit('privilege/UPDATE_SETTINGS_DIRECTION_DOCUMENTS_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_DIRECTION_DOCUMENTS_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  createDirectionDocument({ commit, dispatch, state }, { directionId, data }) {
    createDirectionDocument(directionId, data)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('DIRECTION_DOCUMENTS_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateDirectionDocument({ commit, dispatch, state }, { directionId, documentId, data }) {
    updateDirectionDocument(directionId, documentId, data)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('DIRECTION_DOCUMENTS_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deleteDirectionDocument({ commit, state }, params) {
    deleteDirectionDocument(params)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('DIRECTION_DOCUMENTS_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
