import { ElNotification } from 'element-plus';
import { getHoldsList, createHold, createSingleHold, takeLastHold, updateHold } from '@/methods/lamodaCarRegistry';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';
const state = {
  list: [],
  isLoading: false,
  lastHold: '',
};

const getters = {
  getHoldsList(state) {
    return state.list;
  },
  getLoading(state) {
    return state.isLoading;
  },
  getLastHold(state) {
    return state.lastHold;
  },
};

const mutations = {
  UPDATE_HOLDS_LIST(state, data) {
    state.list = data;
  },
  UPDATE_LOADING(state, data) {
    state.isLoading = data;
  },
  UPDATE_LAST_HOLD(state, data) {
    state.lastHold = data;
  },
};

const actions = {
  getHoldsList({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    getHoldsList(payload)
      .then((response) => {
        commit('UPDATE_HOLDS_LIST', response.data.data);
        commit('privilege/UPDATE_LAMODA_CAR_REGISTRY_PRIVILEGE', response.data.privilege, { root: true });
        commit('UPDATE_LOADING', false);
      })
      .catch(() => {
        commit('privilege/UPDATE_LAMODA_CAR_REGISTRY_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
        commit('UPDATE_LOADING', false);
      });
  },
  takeLastHold({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    takeLastHold(payload)
      .then((response) => {
        commit('UPDATE_LAST_HOLD', response.data.data);
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  updateHold({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    updateHold(payload.payload)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        getHoldsList(payload.data)
          .then((response) => {
            commit('UPDATE_HOLDS_LIST', response.data.data);
            commit('UPDATE_LOADING', false);
          })
          .catch((error) => {
            ElNotification.error({
              dangerouslyUseHTMLString: true,
              message: getErrorMessage(error.response.data),
              duration: 2000,
            });
            commit('UPDATE_LOADING', false);
          });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  createHold({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    createHold(payload.payload)
      .then((response) => {
        const successArr = response.data.data.successDates;
        const failedArr = response.data.data.failedDates;
        const successBlock = successArr.map((elem) => `<span class="size-14">${elem}</span>`);

        const failedBlock = failedArr.map((elem) => `<span class="size-14">${elem}</span>`);
        const finalText = `<div class="flex column">
                            <h3>Расписание и холдирования успешно созданы</h3>
                            <h3>Не удалось создать холдирования на даты:</h3>
                            <div class="flex size-14">${failedBlock.join(',&nbsp')}</div>
                          </div>`;
        ElNotification.success({
          title: '',
          dangerouslyUseHTMLString: true,
          message: failedArr.length ? finalText : 'Расписание и холдирования успешно созданы',
          duration: 2000,
        });
        getHoldsList(payload.data)
          .then((response) => {
            commit('UPDATE_HOLDS_LIST', response.data.data);
            commit('UPDATE_LOADING', false);
          })
          .catch((error) => {
            commit('UPDATE_LOADING', false);
          });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  createSingleHold({ commit, dispatch, state }, payload) {
    commit('UPDATE_LOADING', true);
    createSingleHold(payload.payload)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
