import {
  getRatingPartners,
  createRatingPartner,
  deactivateRatingPartner,
  activateRatingPartner,
  editRatingPartner,
  getCommercialRating,
  createCommercialRating,
  deactivateCommercialRating,
  activateCommercialRating,
  editCommercialRating,
  getSlotGrade,
  createSlotGrade,
  deactivateSlotGrade,
  activateSlotGrade,
  editSlotGrade,
} from '@/methods/ratings';

import { ElNotification } from 'element-plus';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  ratingPartners: [],
  commercialRating: [],
  slotGradeList: [],
};

const getters = {
  getRatingPartners(state) {
    return state.ratingPartners;
  },
  getCommercialRating(state) {
    return state.commercialRating;
  },
  getSlotGrade(state) {
    return state.slotGradeList;
  },
};

const mutations = {
  UPDATE_RATING_PARTNERS(state, data) {
    state.ratingPartners = data;
  },
  UPDATE_COMMERCIAL_RATING(state, data) {
    state.commercialRating = data;
  },
  UPDATE_SLOT_GRADE_LIST(state, data) {
    state.slotGradeList = data;
  },
};

const actions = {
  // партнеры
  getRatingPartners({ commit, state }) {
    getRatingPartners()
      .then((response) => {
        const ratingPartners = response.data.data;
        ratingPartners.forEach((elem) => {
          elem['type'] = 'partner';
        });
        commit('UPDATE_RATING_PARTNERS', ratingPartners);
        commit('privilege/UPDATE_SETTINGS_PARTNER_RATING_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_PARTNER_RATING_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },
  createRatingPartner({ commit, state }, params) {
    createRatingPartner(params).then(() => {
      getRatingPartners()
        .then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'partner';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_RATING_PARTNERS', ratingPartners);
        })
        .catch((error) => {
          ElNotification.error({
            dangerouslyUseHTMLString: true,
            message: getErrorMessage(error.response.data),
            duration: 2000,
          });
        });
    });
  },
  deactivateRatingPartner({ commit, state }, params) {
    deactivateRatingPartner(params)
      .then(() => {
        getRatingPartners().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'partner';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_RATING_PARTNERS', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  activateRatingPartner({ commit, state }, params) {
    activateRatingPartner(params)
      .then(() => {
        getRatingPartners().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'partner';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_RATING_PARTNERS', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  editRatingPartner({ commit, state }, params) {
    const payload = {
      name: params.data.name,
      valueFrom: parseFloat(params.data.minVal),
      valueTo: parseFloat(params.data.maxVal),
    };
    editRatingPartner(params.item.id, payload)
      .then(() => {
        getRatingPartners().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'partner';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_RATING_PARTNERS', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  // коммерческий рейтинг
  getCommercialRating({ commit, state }) {
    getCommercialRating()
      .then((response) => {
        const ratingPartners = response.data.data;
        ratingPartners.forEach((elem) => {
          elem['type'] = 'partner';
        });
        commit('UPDATE_COMMERCIAL_RATING', ratingPartners);
        commit('privilege/UPDATE_SETTINGS_COMMERCIAL_RATING_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_COMMERCIAL_RATING_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },
  createCommercialRating({ commit, state }, params) {
    createCommercialRating(params)
      .then(() => {
        getCommercialRating().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'partner';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_COMMERCIAL_RATING', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  deactivateCommercialRating({ commit, state }, params) {
    deactivateCommercialRating(params)
      .then(() => {
        getCommercialRating().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'partner';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_COMMERCIAL_RATING', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  activateCommercialRating({ commit, state }, params) {
    activateCommercialRating(params)
      .then(() => {
        getCommercialRating().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'partner';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_COMMERCIAL_RATING', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  editCommercialRating({ commit, state }, params) {
    const payload = {
      name: params.data.name,
      valueFrom: parseFloat(params.data.minVal),
      valueTo: parseFloat(params.data.maxVal),
    };
    editCommercialRating(params.item.id, payload)
      .then(() => {
        getCommercialRating().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'partner';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_COMMERCIAL_RATING', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  //рейтинг слотов
  getSlotGrade({ commit, state }) {
    getSlotGrade()
      .then((response) => {
        const ratingPartners = response.data.data;
        ratingPartners.forEach((elem) => {
          elem['type'] = 'slot';
        });
        commit('UPDATE_SLOT_GRADE_LIST', ratingPartners);
        commit('privilege/UPDATE_SETTINGS_SLOT_GRADE_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_SLOT_GRADE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },
  createSlotGrade({ commit, state }, params) {
    createSlotGrade(params)
      .then(() => {
        getSlotGrade().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'slot';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_SLOT_GRADE_LIST', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  deactivateSlotGrade({ commit, state }, params) {
    deactivateSlotGrade(params)
      .then(() => {
        getSlotGrade().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'slot';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_SLOT_GRADE_LIST', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  activateSlotGrade({ commit, state }, params) {
    activateSlotGrade(params)
      .then(() => {
        getSlotGrade().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'slot';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_SLOT_GRADE_LIST', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  editSlotGrade({ commit, state }, params) {
    editSlotGrade(params.itemId, params.data)
      .then(() => {
        getSlotGrade().then((response) => {
          const ratingPartners = response.data.data;
          ratingPartners.forEach((elem) => {
            elem['type'] = 'slot';
          });
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('UPDATE_SLOT_GRADE_LIST', ratingPartners);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
