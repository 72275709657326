import { getRatingMatrix, createRatingMatrix, deleteRatingMatrix, updateRatingMatrix } from '@/methods/ratingMatrix';
import { ElNotification } from 'element-plus';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  matrix: [],
};

const getters = {
  getRatingMatrix(state) {
    return state.matrix;
  },
};

const mutations = {
  UPDATE_RATING_MATRIX(state, data) {
    state.matrix = data;

    if (state.matrix.length) {
      state.matrix.sort((a, b) => {
        if (a.partnerRating < b.partnerRating) return -1;
        if (a.partnerRating > b.partnerRating) return 1;
        if (a.commercialRating < b.commercialRating) return -1;
        if (a.commercialRating > b.commercialRating) return 1;
        return 0;
      });
    }
  },
};

const actions = {
  getRatingMatrix({ commit, state }) {
    getRatingMatrix()
      .then((response) => {
        commit('UPDATE_RATING_MATRIX', response.data.data);
        commit('privilege/UPDATE_RATING_MATRIX_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch(() => {
        commit('privilege/UPDATE_RATING_MATRIX_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  createRatingMatrix({ commit, state }, params) {
    createRatingMatrix(params)
      .then(() => {
        getRatingMatrix().then((response) => {
          commit('UPDATE_RATING_MATRIX', response.data.data);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateRatingMatrix({ commit, state }, params) {
    updateRatingMatrix(params)
      .then((res) => {
        ElNotification.success({
          message: res.data.message,
          duration: 2000,
        });
        getRatingMatrix().then((response) => {
          commit('UPDATE_RATING_MATRIX', response.data.data);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deleteRatingMatrix({ commit, state }, params) {
    deleteRatingMatrix(params)
      .then(() => {
        getRatingMatrix().then((response) => {
          commit('UPDATE_RATING_MATRIX', response.data.data);
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
