/* eslint-disable */
import { getIncidentList, getIncidentItem, createIncidentItem, getIncidentExport } from '@/methods/incident';
import { ElNotification } from 'element-plus';
import store from '@/store';
import router from '@/router';
import { formatDate, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  item: {},
  currentPage: 1,
  filters: {
    'Статус обращения': [
      'В работе',
      'Отменено',
      'Сведения предоставлены',
      'Объединен',
      'Отложен',
      'Ожидание подробностей',
      'Идентифицировать',
    ],
  },
  dataIsUpdated: false,
  isLoading: false,
};

const getters = {
  getIncidentList(state) {
    return state.list;
  },
  getIncidentItem(state) {
    return state.item;
  },
};

const mutations = {
  UPDATE_INCIDENT_LIST(state, data) {
    state.list = data;
  },
  UPDATE_INCIDENT_ITEM(state, data) {
    state.item = data;
  },
  INCIDENT_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
  UPDATE_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  UPDATE_STATUS_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  getIncidentList({ commit, state }, params) {
    getIncidentList(params)
      .then((response) => {
        commit('UPDATE_INCIDENT_LIST', response.data.data);
        commit('privilege/UPDATE_INCIDENT_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  getIncidentItem({ commit, state }, params) {
    getIncidentItem(params).then((response) => {
      commit('UPDATE_INCIDENT_ITEM', response.data.data);
    });
  },

  createIncidentItem({ commit, dispatch, state }, params) {
    createIncidentItem(params)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });
        commit('INCIDENT_DATA_IS_UPDATED', true);
        commit('UPDATE_STATUS_IS_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data, [
            {
              name: 'subjectIds',
              title: 'Тема обращения',
            },
            {
              name: 'description',
              title: 'Описание проблемы',
            },
            {
              name: 'imageUrl',
              title: 'Ссылка на изображение',
            },
            {
              name: 'wmsTruckNumber',
              title: 'Номер грузовика WMS',
            },
            {
              name: 'bookingId',
              title: 'Номер брони',
            },
            {
              name: 'problemItemsQuantity',
              title: 'Количество проблемных единиц',
            },
            {
              name: 'cooperationTypeId',
              title: 'Тип сотрудничества',
            },
            {
              name: 'receiptsList',
              title: 'Список прихода',
            },
            {
              name: 'invoiceNumber',
              title: 'Ручные поставки',
            },
          ]),
          duration: 2000,
        });
      });
  },

  getIncidentExport({ commit, state }, params) {
    getIncidentExport(params).then((response) => {
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', response.data]);

      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `incident-data-${formatDate(new Date())}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
