/* eslint-disable */
import { getSmiOtList, getSmiOtItem, createSmiOtItem, updateSmiOtItem, deleteSmiOtItem } from '@/methods/smiOt';
import { ElNotification } from 'element-plus';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  item: {},
  dataIsUpdated: false,
};

const getters = {
  getSmiOtList(state) {
    return state.list;
  },
  getSmiOtItem(state) {
    return state.item;
  },
};

const mutations = {
  UPDATE_SMI_OT_LIST(state, data) {
    state.list = data;
  },
  UPDATE_SMI_OT_ITEM(state, data) {
    state.item = data;
  },
  SMI_OT_LIST_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
};

const actions = {
  getSmiOtList({ commit, state }, params) {
    getSmiOtList(params).then((response) => {
      commit('UPDATE_SMI_OT_LIST', response.data.data);
    });
  },

  getSmiOtItem({ commit, state }, params) {
    getSmiOtList(params).then((response) => {
      commit('UPDATE_SMI_OT_LIST', response.data.data);
    });
  },

  createSmiOtItem({ commit, dispatch, state }, params) {
    createSmiOtItem(params)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('SMI_OT_LIST_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateSmiOtItem({ commit, dispatch, state }, { id, data }) {
    updateSmiOtItem(id, data)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('SMI_OT_LIST_DATA_IS_UPDATED', true);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deleteSmiOtItem({ commit, state }, params) {
    deleteSmiOtItem(params)
      .then((response) => {
        commit('SMI_OT_LIST_DATA_IS_UPDATED', true);

        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
