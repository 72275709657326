/* eslint-disable */
import axios from 'axios';
import { refreshToken } from '@/methods/auth';
import store from '@/store';

axios.defaults.withCredentials = true;

export const loginApiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

let tokenIsRefreshing = false;

const defaultConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const defaultApiInstance = axios.create(defaultConfig);

defaultApiInstance.interceptors.request.use(
  function (defaultConfig) {
    const token = localStorage.getItem('token');
    if (token) defaultConfig.headers['X-AUTH-TOKEN'] = `${token}`;

    return defaultConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

defaultApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const defaultConfig = error.config;

    if (error.response) {
      if (error.response.status === 401 && !defaultConfig._retry) {
        defaultConfig._retry = true;

        try {
          if (!tokenIsRefreshing) {
            tokenIsRefreshing = true;

            const rs = await refreshToken({
              refreshToken: localStorage.getItem('refreshToken'),
            });

            store.commit('auth/UPDATE_TOKEN', rs.data.data.token);
            store.commit('auth/UPDATE_REFRESH_TOKEN', rs.data.data.refreshToken);
          }

          return defaultApiInstance(defaultConfig);
        } catch (_error) {
          await store.dispatch('auth/logout');
        }
      }

      if (error.response.status === 404 && !defaultConfig._retry) {
        defaultConfig._retry = true;
        location.href = '/404';
      }
    }

    return Promise.reject(error);
  }
);
