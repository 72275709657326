/* eslint-disable */
import { getInboundList, createInboundItem, updateInboundItem, deactivateInboundItem } from '@/methods/inbound';
import { ElNotification } from 'element-plus';
import router from '@/router';
import store from '@/store';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  dataIsUpdated: false,
  isLoading: false,
  filters: {
    'Статус маршрута': 'Активный',
  },
};

const getters = {
  getInboundList(state) {
    return state.list;
  },
};

const mutations = {
  UPDATE_INBOUND_LIST(state, data) {
    state.list = data;
    if (state.list.data.length) {
      state.list.data = state.list.data.sort((a, b) => (a.active < b.active ? 1 : a.active > b.active ? -1 : 0));
    }
  },
  INBOUND_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
  UPDATE_IS_LOADING(state, data) {
    state.isLoading = data;
  },
  UPDATE_FILTERS(state, data) {
    state.filters = data;
  },
};

const actions = {
  getInboundList({ commit, state }, params) {
    getInboundList(params)
      .then((response) => {
        commit('UPDATE_INBOUND_LIST', response.data.data);
        commit('privilege/UPDATE_INBOUND_PRIVILEGE', response.data.privilege, { root: true });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  createInboundItem({ commit, dispatch, state }, params) {
    commit('UPDATE_IS_LOADING', true);
    createInboundItem(params)
      .then((response) => {
        const successArr = response.data.data.successDates;
        const failedArr = response.data.data.failedDates;
        const successBlock = successArr.map((elem) => `<span>${elem}</span>`);

        const failedBlock = failedArr.map((elem) => `<span>${elem}</span>`);
        const finalText = `<div class="flex column">
                            <h3>Расписание успешно создано</h3>
                            <h3>Не удалось создать брони на даты:</h3>
                            <div class="flex size-14">${failedBlock.join(',&nbsp')}</div>
                          </div>`;
        ElNotification.success({
          title: '',
          dangerouslyUseHTMLString: true,
          message: failedArr.length ? finalText : 'Расписание и брони успешно созданы',
          duration: 2000,
        });

        commit('INBOUND_DATA_IS_UPDATED', true);
        commit('UPDATE_IS_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      });
  },

  updateInboundItem({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_IS_LOADING', true);
    updateInboundItem(id, data)
      .then((response) => {
        const successArr = response.data.data.successDates;
        const failedArr = response.data.data.failedDates;
        const successBlock = successArr.map((elem) => `<span>${elem}</span>`);

        const failedBlock = failedArr.map((elem) => `<span>${elem}</span>`);
        const finalText = `<div class="flex column">
                            <h3>Расписание успешно создано</h3>
                            <h3>Не удалось создать брони на даты:</h3>
                            <div class="flex size-14">${failedBlock.join(',&nbsp')}</div>
                          </div>`;
        ElNotification.success({
          title: '',
          dangerouslyUseHTMLString: true,
          message: failedArr.length ? finalText : 'Расписание и брони успешно созданы',
          duration: 2000,
        });

        commit('INBOUND_DATA_IS_UPDATED', true);
        commit('UPDATE_IS_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      });
  },

  deactivateInboundItem({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_IS_LOADING', true);
    deactivateInboundItem(id, data)
      .then((response) => {
        ElNotification.success({
          message: response.data.message,
          duration: 2000,
        });

        commit('INBOUND_DATA_IS_UPDATED', true);
        commit('UPDATE_IS_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
