/* eslint-disable */
import {
  getWeekDays,
  getRestrictionList,
  getReturnStatusList,
  getBoxStatusList,
  getBoxSizeList,
  getBookingStatusList,
  getDirectionTypesList,
  getDocumentsStatusAtReceptionList,
  getDocumentsStatusOnResultList,
  getReturnTypeList,
  getParishList,
  getReasonsForNotShippingOnTimeList,
  getShipmentStatusList,
  getReasonsForNotUnloadOnTime,
  getHoldingTypes,
  getIncidentSubjectList,
  getIncidentStatusList,
  getCarOrderStatusList,
  getWorkGroupList,
  getChangeLogObjectList,
} from '@/methods/collection';

const state = {
  weekDays: [],
  restrictionList: [],
  returnStatusList: [],
  shipmentStatusList: [],
  boxStatusList: [],
  boxSizeList: [],
  bookingStatusList: [],
  directionTypesList: [],
  documentsStatusAtReceptionList: [],
  documentsStatusOnResultList: [],
  returnTypeList: [],
  parishList: [],
  reasonsForNotShippingOnTimeList: [],
  reasonsForNotUnloadOnTime: [],
  holdingTypes: [],
  incidentSubjectList: [],
  incidentStatusList: [],
  carOrderStatusList: [],
  workGroupList: [],
  changeLogObjectList: [],
};

const getters = {
  getWeekDays(state) {
    return state.weekDays;
  },
  getRestrictionList(state) {
    return state.restrictionList;
  },
  getReturnStatusList(state) {
    return state.returnStatusList;
  },
  getShipmentStatusList(state) {
    return state.shipmentStatusList;
  },
  getBoxStatusList(state) {
    return state.boxStatusList;
  },
  getBoxSizeList(state) {
    return state.boxSizeList;
  },
  getBookingStatusList(state) {
    return state.bookingStatusList;
  },
  getDirectionTypesList(state) {
    return state.directionTypesList;
  },
  getDocumentsStatusAtReceptionList(state) {
    return state.documentsStatusAtReceptionList;
  },
  getDocumentsStatusOnResultList(state) {
    return state.documentsStatusOnResultList;
  },
  getReturnTypeList(state) {
    return state.returnTypeList;
  },
  getParishList(state) {
    return state.parishList;
  },
  getReasonsForNotShippingOnTimeList(state) {
    return state.reasonsForNotShippingOnTimeList;
  },
  getReasonsForNotUnloadOnTime(state) {
    return state.reasonsForNotUnloadOnTime;
  },
  getHoldingTypes(state) {
    return state.holdingTypes;
  },
  getIncidentSubjectList(state) {
    return state.incidentSubjectList;
  },
  getCarOrderStatusList(state) {
    return state.carOrderStatusList;
  },
  getWorkGroupList(state) {
    return state.workGroupList;
  },
  getChangeLogObjectList(state) {
    return state.changeLogObjectList;
  },
};

const mutations = {
  UPDATE_WEEK_DAYS(state, data) {
    state.weekDays = data;
  },
  UPDATE_RESTRICTION_LIST(state, data) {
    state.restrictionList = data;
  },
  UPDATE_RETURN_STATUS_LIST(state, data) {
    state.returnStatusList = data;
  },
  UPDATE_SHIPMENT_STATUS_LIST(state, data) {
    state.shipmentStatusList = data;
  },
  UPDATE_BOX_STATUS_LIST(state, data) {
    state.boxStatusList = data;
  },
  UPDATE_BOX_SIZE_LIST(state, data) {
    state.boxSizeList = data;
  },
  UPDATE_BOOKING_STATUS_LIST(state, data) {
    state.bookingStatusList = data;
  },
  UPDATE_DIRECTION_TYPES_LIST(state, data) {
    state.directionTypesList = data;
  },
  UPDATE_DOCUMENTS_STATUS_AT_RECEPRION_LIST(state, data) {
    state.documentsStatusAtReceptionList = data;
  },
  UPDATE_DOCUMENTS_STATUS_ON_RESULT_LIST(state, data) {
    state.documentsStatusOnResultList = data;
  },
  UPDATE_RETURN_TYPE_LIST(state, data) {
    state.returnTypeList = data;
  },
  UPDATE_PARISH_LIST(state, data) {
    state.parishList = data;
  },
  UPDATE_REASONS_FOR_NOT_SHIPPING_ON_TIME_LIST(state, data) {
    state.reasonsForNotShippingOnTimeList = data;
  },
  UPDATE_REASONS_FOR_NOT_UNLOAD_ON_TIME(state, data) {
    state.reasonsForNotUnloadOnTime = data;
  },
  UPDATE_HOLDING_TYPES(state, data) {
    state.holdingTypes = data;
  },
  UPDATE_INCIDENT_SUBJECT_LIST(state, data) {
    state.incidentSubjectList = data;
  },
  UPDATE_INCIDENT_STATUS_LIST(state, data) {
    state.incidentStatusList = data;
  },
  UPDATE_CAR_ORDER_STATUS_LIST(state, data) {
    state.carOrderStatusList = data;
  },
  UPDATE_WORK_GROUP_LIST(state, data) {
    state.workGroupList = data;
  },
  UPDATE_CHANGE_LOG_OBJECT_LIST(state, data) {
    state.changeLogObjectList = data;
  },
};

const actions = {
  getWeekDays({ commit, state }, params) {
    getWeekDays(params).then((response) => {
      commit('UPDATE_WEEK_DAYS', response.data.data);
    });
  },

  getRestrictionList({ commit, state }, params) {
    getRestrictionList(params).then((response) => {
      commit('UPDATE_RESTRICTION_LIST', response.data.data);
    });
  },

  getReturnStatusList({ commit, state }, params) {
    getReturnStatusList(params).then((response) => {
      commit('UPDATE_RETURN_STATUS_LIST', response.data.data);
    });
  },
  getShipmentStatusList({ commit, state }) {
    getShipmentStatusList().then((response) => {
      commit('UPDATE_SHIPMENT_STATUS_LIST', response.data.data);
    });
  },
  getBoxStatusList({ commit, state }, params) {
    getBoxStatusList(params).then((response) => {
      commit('UPDATE_BOX_STATUS_LIST', response.data.data);
    });
  },

  getBoxSizeList({ commit, state }, params) {
    getBoxSizeList(params).then((response) => {
      commit('UPDATE_BOX_SIZE_LIST', response.data.data);
    });
  },

  getBookingStatusList({ commit, state }, params) {
    getBookingStatusList(params).then((response) => {
      commit('UPDATE_BOOKING_STATUS_LIST', response.data.data);
    });
  },

  getDirectionTypesList({ commit, state }, params) {
    getDirectionTypesList(params).then((response) => {
      commit('UPDATE_DIRECTION_TYPES_LIST', response.data.data);
    });
  },

  getDocumentsStatusAtReceptionList({ commit, state }, params) {
    getDocumentsStatusAtReceptionList(params).then((response) => {
      commit('UPDATE_DOCUMENTS_STATUS_AT_RECEPRION_LIST', response.data.data);
    });
  },

  getDocumentsStatusOnResultList({ commit, state }, params) {
    getDocumentsStatusOnResultList(params).then((response) => {
      commit('UPDATE_DOCUMENTS_STATUS_ON_RESULT_LIST', response.data.data);
    });
  },

  getReturnTypeList({ commit, state }, params) {
    getReturnTypeList(params).then((response) => {
      commit('UPDATE_RETURN_TYPE_LIST', response.data.data);
    });
  },

  getParishList({ commit, state }, params) {
    getParishList(params).then((response) => {
      commit('UPDATE_PARISH_LIST', response.data.data);
    });
  },

  getReasonsForNotShippingOnTimeList({ commit, state }, params) {
    getReasonsForNotShippingOnTimeList(params).then((response) => {
      commit('UPDATE_REASONS_FOR_NOT_SHIPPING_ON_TIME_LIST', response.data.data);
    });
  },

  getReasonsForNotUnloadOnTime({ commit, state }, params) {
    getReasonsForNotUnloadOnTime(params).then((response) => {
      commit('UPDATE_REASONS_FOR_NOT_UNLOAD_ON_TIME', response.data.data);
    });
  },

  getHoldingTypes({ commit }) {
    getHoldingTypes().then((response) => {
      commit('UPDATE_HOLDING_TYPES', response.data.data);
    });
  },

  getIncidentSubjectList({ commit, state }, params) {
    getIncidentSubjectList(params).then((response) => {
      commit('UPDATE_INCIDENT_SUBJECT_LIST', response.data.data);
    });
  },

  getIncidentStatusList({ commit, state }, params) {
    getIncidentStatusList(params).then((response) => {
      commit('UPDATE_INCIDENT_STATUS_LIST', response.data.data);
    });
  },

  getCarOrderStatusList({ commit, state }, params) {
    getCarOrderStatusList(params).then((response) => {
      commit('UPDATE_CAR_ORDER_STATUS_LIST', response.data.data);
    });
  },

  getWorkGroupList({ commit, state }, params) {
    getWorkGroupList(params).then((response) => {
      commit(
        'UPDATE_WORK_GROUP_LIST',
        response.data.data.sort((a, b) => a.id - b.id)
      );
    });
  },

  getChangeLogObjectList({ commit, state }, params) {
    getChangeLogObjectList(params).then((response) => {
      commit('UPDATE_CHANGE_LOG_OBJECT_LIST', response.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
